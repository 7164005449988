import Button from '~/components/Button'
import PageWrapper from '~/modules/layout/components/PageWrapper'

const FourOhFour = () => (
  <PageWrapper size="skinny">
    <div className="space-y-8">
      <div className="flex flex-col">
        <span className="text-mid dark:text-light">404</span>
        <span className="text-2xl font-bold">Page not found</span>
      </div>
      <div className="logo-gradient flex flex-col rounded-2xl p-8 font-bold text-white shadow-2xl">
        <span className="text-2xl">FOUR,</span>
        <span className="pl-16 text-4xl sm:pl-20 md:pl-24 lg:pl-28">OH</span>
        <span className="text-right text-6xl">FOUR!</span>
      </div>
      <div className="flex flex-col items-center space-y-2">
        <Button to="/" text="Go home" size="lg" />
        <span className="text-sm text-mid dark:text-light">(You're lost)</span>
      </div>
    </div>
  </PageWrapper>
)

export default FourOhFour
