import type { ReactElement } from 'react'
import classNames from 'classnames'

import { type SearchItem } from './types'

const SearchItemList = ({
  label,
  textAccent,
  items,
  itemRenderer
}: {
  label: string | null
  textAccent: string
  items?: SearchItem[] | null
  itemRenderer: ({ item }: { item: SearchItem }) => ReactElement
}) => {
  return (
    <div className="lg:w-xs">
      <h5 className={classNames(textAccent, 'mb-3 border-b-4 pb-2 pl-3 lg:text-2xl')}>{label}</h5>
      {items && items.length ? (
        <ul className="space-y-1">
          {items.map(item => (
            <li key={item.id}>{itemRenderer({ item })}</li>
          ))}
        </ul>
      ) : (
        <p className="mt-1 py-2 text-sm text-light">No {(label ?? 'results').toLowerCase()} found</p>
      )}
    </div>
  )
}

export default SearchItemList
