import { useMemo, useState } from 'react'
import { useFetcher } from '@remix-run/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import * as Pop from '@radix-ui/react-popover'
import { useDebouncedCallback } from 'use-debounce'

import useMediaQuery from '~/hooks/useMediaQuery'

import type { SearchResponse } from '~/routes/api.search'
import Spinner from '~/components/Spinner'
import TextInput from '~/components/forms/TextInput'
import SearchItemList from './SearchItemList'
import SearchItemLink from './SearchItemLink'
import SearchItemPrintable from './SearchItemPrintable'

import { type SearchItem } from './types'

const SearchBar = () => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const { data, submit, state } = useFetcher<SearchResponse>()
  const [open, setOpen] = useState(false)

  const onChange = useDebouncedCallback((text: string) => {
    if (text.length > 2) {
      setOpen(true)
      submit({ q: text }, { action: '/api/search', method: 'get' })
    }
  }, 500)

  const loading = state === 'submitting' || state === 'loading'

  const content = useMemo(() => {
    if (!data) {
      return <p className="text-center">Enter a search term to see results</p>
    }

    if ('error' in data) {
      return (
        <>
          <h5>Uh oh!</h5>
          <p>{data.error}</p>
        </>
      )
    }

    return (
      <>
        <SearchItemList
          label="Videos"
          textAccent="text-lightBlue border-b-lightBlue"
          items={data.videos as SearchItem[]}
          itemRenderer={({ item }) => (
            <SearchItemLink
              key={item.id}
              item={item}
              accentColor="group-hover:text-lightBlue"
              listKey="video"
              onClick={() => {
                setOpen(false)
              }}
            />
          )}
        />
        <SearchItemList
          label="Series"
          textAccent="text-lightPink border-b-lightPink"
          items={data.sets}
          itemRenderer={({ item }) => (
            <SearchItemLink
              key={item.id}
              item={item}
              accentColor="group-hover:text-lightPink"
              listKey="set"
              onClick={() => {
                setOpen(false)
              }}
            />
          )}
        />
        <SearchItemList
          label="Printables"
          textAccent="text-lightOrange border-b-lightOrange"
          items={data.printables}
          itemRenderer={({ item }) => (
            <SearchItemPrintable
              key={item.id}
              item={item}
              accentColor="group-hover:text-lightOrange"
              listKey="printable"
              onClick={() => {
                setOpen(false)
              }}
            />
          )}
        />
      </>
    )
  }, [data])

  return (
    <Pop.Root open={open} onOpenChange={setOpen} modal>
      <Pop.Trigger className="rounded-sm p-1 px-2 hover:text-baseOrange" aria-label="Search">
        <div className="text-md flex items-center space-x-2 text-sm font-bold md:text-base">
          <MagnifyingGlassIcon className="h-5 w-5 md:h-6 md:w-6" />
          <p className="hidden sm:block">Search</p>
        </div>
      </Pop.Trigger>
      {isMobile && <Pop.Anchor className="fixed left-1/2 top-8" />}
      <Pop.Content
        side="bottom"
        sideOffset={4}
        align="center"
        className="radix-modal-animation relative w-sm min-w-sm max-w-90vw rounded-xl bg-gradient-to-br from-basePurple via-basePink to-baseOrange p-0.5 text-sm text-white shadow-xl lg:w-auto">
        <div
          className="flex flex-col space-y-6 rounded-[10px]  bg-black p-3.5 pr-1.5"
          style={{ height: 'calc(100% - 2px)' }}>
          <div className="relative max-w-sm pr-2">
            <TextInput
              placeholder="Search for videos, printables and more..."
              type="search"
              defaultValue={data && 'query' in data ? data.query : ''}
              onChange={event => {
                onChange(event.target.value)
              }}
            />
            {loading && (
              <div className="absolute right-4 top-1/2 flex h-full -translate-y-1/2 transform flex-col justify-center pt-1">
                <Spinner d={5} className="dark:text-dark" />
              </div>
            )}
          </div>
          <div style={{ maxHeight: '70vh' }} className="nice-scroll overflow-y-auto">
            <div className="h-full space-y-4 pr-2 lg:flex lg:space-x-4 lg:space-y-0">{content}</div>
          </div>
          <Pop.Close className="self-center px-2 py-1 text-xs text-light hover:underline">Close</Pop.Close>
        </div>
      </Pop.Content>
    </Pop.Root>
  )
}

export default SearchBar
