import * as Sentry from '@sentry/remix'
import { useEffect } from 'react'
import { Links, Meta, Scripts } from '@remix-run/react'
import * as React from 'react'

import { setupFlodesk } from '~/lib/flodesk'
import { ConditionalScrollRestoration } from '~/modules/layout/components/ConditionalScrollRestoration'
import ScrollToTopButton from '~/modules/layout/components/ScrollToTopButton'
import useRootData from '~/hooks/useRootData'
import { loadRecaptcha } from '~/services/recaptcha'

const Document = ({ children, title }: { children: React.ReactNode; title?: string }) => {
  const root = useRootData()
  const { currentUser } = root || {}

  useEffect(() => {
    loadRecaptcha(() => {}, root?.recaptchaSiteKey)
  }, [root?.recaptchaSiteKey])

  useEffect(() => {
    setupFlodesk()
  }, [])

  // Set sentry user on the client
  if (currentUser) {
    const { email, id, name } = currentUser
    Sentry.setUser({ email, id, name })
  } else {
    Sentry.setUser(null)
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body className="bg-black">
        {children}
        <ScrollToTopButton />
        <ConditionalScrollRestoration />
        <Scripts />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z1C42MJLMS" />
        {process.env.NODE_ENV === 'production' && (
          <>
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-Z1C42MJLMS');
            `
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '311741020288440');
              fbq('track', 'PageView');
              </script>
              <noscript><img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=311741020288440&ev=PageView&noscript=1"
              />`
              }}
            />
          </>
        )}
      </body>
    </html>
  )
}

export default Document
