// This function was taken from Flodesk integration form, inside of Flodesk itself
export function setupFlodesk() {
  ;(function (w, d, t, h, s, n) {
    w.FlodeskObject = n
    var fn = function () {
      ;(w[n].q = w[n].q || []).push(arguments)
    }
    w[n] = w[n] || fn
    var f = d.getElementsByTagName(t)[0]
    var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60
    var sm = d.createElement(t)
    sm.async = true
    sm.type = 'module'
    sm.src = h + s + '.mjs' + v
    f.parentNode.insertBefore(sm, f)
    var sn = d.createElement(t)
    sn.async = true
    sn.noModule = true
    sn.src = h + s + '.js' + v
    f.parentNode.insertBefore(sn, f)
  })(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'fd')
}

export function renderSignupForm() {
  window.fd?.('form', {
    formId: '660397b9701cf0bc64ab4110',
    containerEl: '#fd-form-660397b9701cf0bc64ab4110'
  })
}
