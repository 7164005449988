import { useLoaderData } from '@remix-run/react'

import { createApolloClient } from '~/config/apollo'
import { graphql } from '~/graphql/codegen'
import type { GetBannerAlertQuery, GetBannerAlertQueryVariables } from '~/graphql/codegen/graphql'
import type { Intent } from '~/services/theme-service'
import Banner from './Banner'
import type { loader } from '~/root'
import GenericDocument from '~/components/GenericDocument'

export type BannerAlertType = NonNullable<NonNullable<GetBannerAlertQuery['bannerAlerts']>[0]>

export const GET_BANNER_ALERT = graphql(`
  query GetBannerAlert($where: BannerAlertWhereInput, $orderBy: [BannerAlertOrderByInput!]) {
    bannerAlerts(where: $where, orderBy: $orderBy) {
      id
      kind
      title
      userDismissable
      bodyText {
        document
      }
    }
  }
`)

export const getBannerAlert = async ({ request, isAuthenticated }: { request: Request; isAuthenticated: boolean }) => {
  const now = new Date().toISOString()

  const variables = {
    where: {
      status: { equals: 'published' },
      publishDate: { lte: now },

      AND: [
        {
          OR: [
            {
              expiresAt: {
                gt: now
              }
            },
            {
              expiresAt: null
            }
          ]
        },
        {
          OR: [
            {
              authenticatedUsersOnly: { equals: false }
            },
            {
              authenticatedUsersOnly: { equals: isAuthenticated }
            }
          ]
        },
        {
          OR: [
            {
              guestsOnly: { equals: false }
            },
            {
              guestsOnly: { equals: !isAuthenticated }
            }
          ]
        }
      ]
    },
    orderBy: [
      {
        createdAt: 'desc'
      }
    ]
  } as GetBannerAlertQueryVariables

  const client = createApolloClient(request)
  const response = await client.query<GetBannerAlertQuery, GetBannerAlertQueryVariables>({
    query: GET_BANNER_ALERT,
    variables
  })
  const data = response?.data
  const bannerAlert = data?.bannerAlerts?.[0]
  return bannerAlert
}

const BannerAlert = () => {
  const data = useLoaderData<typeof loader>()

  const bannerAlert = data?.bannerAlert

  if (!bannerAlert) {
    return null
  }

  return (
    <Banner
      intent={bannerAlert.kind as Intent}
      isRounded={false}
      id={bannerAlert.id}
      dismissable={bannerAlert.userDismissable}
      className="z-20">
      <div className="flex-1 text-center text-xs md:text-sm lg:text-base">
        {bannerAlert.title && <h3 className="pb-1 font-display">{bannerAlert.title}</h3>}
        <GenericDocument document={bannerAlert.bodyText?.document} />
      </div>
    </Banner>
  )
}

export default BannerAlert
