import { Bars3Icon } from '@heroicons/react/24/outline'
import {
  CalendarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  DocumentTextIcon,
  StarIcon,
  InformationCircleIcon,
  ArrowRightOnRectangleIcon,
  PlayIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  UserCircleIcon,
  ShoppingBagIcon
  //BookOpenIcon
} from '@heroicons/react/24/solid'
import * as DD from '@radix-ui/react-dropdown-menu'
import { Link } from '@remix-run/react'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import React, { Fragment } from 'react'
import useMediaQuery from '~/hooks/useMediaQuery'

// import PartyIcon from './icons/Party'
import BlogIcon from './icons/Blog'

interface MenuItem {
  name: string
  path: string
  Icon: null | ((props: React.ComponentProps<'svg'>) => React.ReactElement) | string
  isRenderedOnDesktop: boolean
  addSeparatorBefore: boolean
  textColor?: string
  backgroundFocusColor?: string
}

const getMenuItems = (isLoggedIn: boolean, isMobile: boolean): MenuItem[] =>
  [
    !isLoggedIn
      ? {
          name: 'Start free trial',
          path: '/cub-club',
          Icon: SparklesIcon,
          isRenderedOnDesktop: true,
          addSeparatorBefore: false,
          textColor: 'text-lightPurple',
          backgroundFocusColor: 'focus:bg-surfacePurple focus:text-basePurple'
        }
      : null,
    { name: 'Videos', path: '/videos', Icon: PlayIcon, isRenderedOnDesktop: false, addSeparatorBefore: !isLoggedIn },
    {
      name: 'Printables',
      path: '/printables',
      Icon: DocumentTextIcon,
      isRenderedOnDesktop: false,
      addSeparatorBefore: false
    },
    isLoggedIn
      ? {
          name: 'Favourites',
          path: '/cub-club/account/favourites',
          Icon: StarIcon,
          isRenderedOnDesktop: false,
          addSeparatorBefore: false
        }
      : null,
    {
      name: 'About',
      path: '/about',
      Icon: InformationCircleIcon,
      isRenderedOnDesktop: true,
      addSeparatorBefore: isMobile || !isLoggedIn
    },
    {
      name: 'Blog',
      path: '/blog',
      Icon: BlogIcon,
      isRenderedOnDesktop: true,
      addSeparatorBefore: false
    },
    { name: 'Events', path: '/events', Icon: CalendarIcon, isRenderedOnDesktop: true, addSeparatorBefore: false },
    // No longer needing to display this
    // {
    //   name: 'Party',
    //   path: '/party',
    //   Icon: PartyIcon,
    //   isRenderedOnDesktop: true,
    //   addSeparatorBefore: false,
    //   backgroundFocusColor: 'focus:bg-basePurple'
    // },
    {
      name: 'Shop',
      path: 'https://shop.pevanandsarah.com',
      Icon: ShoppingBagIcon,
      isRenderedOnDesktop: true,
      addSeparatorBefore: false
    },
    {
      name: 'Contact',
      path: '/contact',
      Icon: ChatBubbleOvalLeftEllipsisIcon,
      isRenderedOnDesktop: true,
      addSeparatorBefore: true
    },
    { name: 'Help', path: '/help', Icon: QuestionMarkCircleIcon, isRenderedOnDesktop: true, addSeparatorBefore: false },
    isLoggedIn
      ? {
          name: 'Account',
          path: '/cub-club/account/personal',
          Icon: UserCircleIcon,
          isRenderedOnDesktop: true,
          addSeparatorBefore: true
        }
      : null
  ].filter(item => !!item) as MenuItem[]

const MenuItem = ({ children, backgroundFocusColor }: { children: ReactNode; backgroundFocusColor?: string }) => (
  <DD.DropdownMenuItem
    asChild
    className={classNames(
      'flex w-full items-center rounded-md px-2 py-2 font-bold leading-none ',
      'focus-within:outline-none  focus:ring-0 focus:ring-offset-0',
      backgroundFocusColor ? backgroundFocusColor : 'focus:bg-baseOrange'
    )}>
    {children}
  </DD.DropdownMenuItem>
)

interface MenuDropdownProps {
  onClickLogout?: () => void
  isLoggedIn: boolean
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({ onClickLogout = () => {}, isLoggedIn }) => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  return (
    <DD.Root modal={false}>
      <DD.Trigger className="ml-2 hover:text-baseOrange" aria-label="Menu">
        <Bars3Icon className="h-7 w-7 md:h-8 md:w-8" />
      </DD.Trigger>
      <DD.Content
        align="start"
        className="radix-modal-animation w-64 rounded-xl border-2 border-light bg-black p-4 font-copy text-base text-white shadow-xl dark:border-white">
        {getMenuItems(isLoggedIn, isMobile).map(
          ({ name, path, Icon, isRenderedOnDesktop, addSeparatorBefore, textColor, backgroundFocusColor }) => {
            const isSvg = typeof Icon === 'string'
            const iconComponent = isSvg ? (
              <img src={Icon} alt="Icon" className="block h-5 w-5" />
            ) : (
              Icon && <Icon className="block h-5 w-5" />
            )
            return isMobile || isRenderedOnDesktop ? (
              <Fragment key={name}>
                {addSeparatorBefore && <DD.Separator className="m-2 bg-white" style={{ height: 1 }} />}
                <MenuItem backgroundFocusColor={backgroundFocusColor}>
                  {path.startsWith('http') ? (
                    <a
                      href={path}
                      className={classNames(
                        'flex w-full items-center justify-between focus:ring-0 focus:ring-offset-0',
                        textColor
                      )}>
                      <span className="block">{name}</span>
                      {iconComponent}
                    </a>
                  ) : (
                    <Link
                      prefetch="intent"
                      to={path}
                      className={classNames(
                        'flex w-full items-center justify-between focus:ring-0 focus:ring-offset-0',
                        textColor
                      )}>
                      <span className="block">{name}</span>
                      {iconComponent}
                    </Link>
                  )}
                </MenuItem>
              </Fragment>
            ) : null
          }
        )}
        {isLoggedIn && (
          <MenuItem>
            <button
              type="button"
              className="flex w-full items-center justify-between focus:ring-0 focus:ring-offset-0"
              onClick={onClickLogout}>
              <span>Log out</span>
              <ArrowRightOnRectangleIcon className="h-5 w-5" />
            </button>
          </MenuItem>
        )}
        <DD.Arrow className="fill-light dark:fill-white" offset={7} height={8} width={16} />
      </DD.Content>
    </DD.Root>
  )
}

export default MenuDropdown
