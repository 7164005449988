import { useRouteError, isRouteErrorResponse } from '@remix-run/react'

import FourOhFour from '~/modules/error-handling/components/404'
import Document from '~/modules/layout/components/Document'
import { isDefinitelyAnError } from '~/modules/error-handling/utils/error-handling'

export function ErrorBoundary() {
  const error = useRouteError()

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    let content
    switch (error.status) {
      case 401:
        content = <p>Oops! Looks like you tried to visit a page that you do not have access to.</p>
        break
      case 404:
        content = <FourOhFour />
        break

      default:
        throw new Error(error.data || error.statusText)
    }

    return (
      <Document title={`${error.status}: ${error.statusText} | Pevan & Sarah`}>
        <div className="grid min-h-full grid-rows-layout">
          <main className="main-content z-10 bg-white text-dark dark:bg-dark dark:text-white">
            <>{content}</>
          </main>
        </div>
      </Document>
    )
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage = 'Unknown error'
  if (isDefinitelyAnError(error)) {
    errorMessage = error.message
  }

  return (
    <Document title="Error!">
      <div className="grid min-h-full grid-rows-layout">
        <main className="main-content z-10 bg-white text-dark dark:bg-dark dark:text-white">
          <h1>There was an error</h1>
          <pre>{errorMessage}</pre>
        </main>
      </div>
    </Document>
  )
}

export default ErrorBoundary
