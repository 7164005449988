import { useLocation, NavLink } from '@remix-run/react'
import cx from 'classnames'

import useRootData from '~/hooks/useRootData'
import { SignupStatus } from '~/modules/signup/types/signup-status'
import ContinueToPaymentButton from '~/modules/signup/components/ContinueToPaymentButton'

const ContinueSignupNavLink = () => {
  const location = useLocation()
  const root = useRootData()
  const { currentCustomer } = root || {}
  const { signupStatus, validFreeAccess } = currentCustomer || {}
  const isLoggedIn = !!currentCustomer

  const isOnboardingPage =
    location.pathname.startsWith('/cub-club/join') || location.pathname.startsWith('/cub-club/activate')

  if (!isLoggedIn || !signupStatus || isOnboardingPage) {
    return null
  }

  return (
    <>
      <li className="ml-2 font-bold">
        <>
          {signupStatus === SignupStatus.DETAILS_ENTERED && (
            <NavLink
              to="/cub-club/join/activate"
              className={cx(
                'flex items-center rounded-full bg-lightOrange px-3 py-1 text-dark transition-all duration-200 md:px-4',
                'hover:bg-baseOrange hover:shadow-lg'
              )}>
              <p className="ml-1 text-sm md:text-base">Continue Signup</p>
            </NavLink>
          )}

          {signupStatus === SignupStatus.ACCOUNT_ACTIVATED && !validFreeAccess && <ContinueToPaymentButton />}
        </>
      </li>
    </>
  )
}

export default ContinueSignupNavLink
