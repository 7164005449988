import type { SVGProps } from 'react'

export const BlogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    zoomAndPan="magnify"
    viewBox="0 0 810 809.999993"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    fill="currentColor"
    {...props}>
    <defs>
      <clipPath id="e40a7c9882">
        <path d="M 668 440 L 809.425781 440 L 809.425781 583 L 668 583 Z M 668 440 " clipRule="nonzero" />
      </clipPath>
    </defs>
    <path
      d="M 668.769531 0 C 677.179688 2.15625 685.449219 4.890625 693.503906 8.125 C 710.835938 15.03125 725.722656 27.183594 735.933594 42.859375 C 746.144531 58.535156 751.320312 77.015625 750.671875 95.714844 C 751.320312 188.910156 750.671875 281.960938 750.671875 375.300781 C 750.671875 377.027344 750.671875 378.96875 750.097656 381.269531 C 729.246094 376.953125 707.527344 379.542969 688.253906 388.675781 L 688.253906 125.914062 L 62.921875 125.914062 C 62.921875 128.503906 62.417969 131.308594 62.417969 134.042969 L 62.417969 651.078125 C 62.417969 676.246094 74.355469 688.183594 99.238281 688.183594 L 406.941406 688.183594 C 400.972656 708.535156 395.292969 728.667969 389.179688 747.871094 C 387.957031 749.59375 385.945312 750.671875 383.785156 750.746094 L 98.230469 750.746094 C 77.449219 751.609375 57.023438 745.640625 40.054688 733.703125 C 23.15625 721.765625 10.570312 704.507812 4.386719 684.730469 C 2.734375 679.480469 1.4375 674.160156 0 668.550781 L 0 82.335938 C 0.574219 81.042969 1.152344 79.675781 1.511719 78.238281 C 6.398438 47.316406 26.824219 20.996094 55.515625 8.628906 C 64.359375 5.105469 73.421875 2.230469 82.625 0 L 668.769531 0 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      d="M 557.953125 751.609375 C 555.003906 736.148438 552.273438 721.335938 549.253906 706.664062 C 548.820312 704.722656 547.453125 703.140625 545.65625 702.277344 C 530.84375 698.96875 515.8125 695.949219 500.640625 692.929688 C 495.105469 711.699219 490.359375 729.820312 484.460938 747.582031 C 482.304688 754.054688 484.53125 761.101562 489.855469 765.34375 C 493.664062 769.515625 499.921875 770.449219 504.738281 767.5 Z M 633.171875 520.703125 L 732.265625 619.003906 C 730.753906 620.730469 728.957031 622.671875 727.160156 624.398438 C 678.621094 673.011719 629.792969 721.621094 580.75 770.160156 C 577.011719 773.902344 572.480469 776.632812 567.519531 778.214844 C 532.929688 788.785156 498.195312 798.925781 463.390625 808.847656 C 459.363281 810.144531 455.121094 810.289062 451.09375 809.496094 C 447.714844 808.5625 444.980469 806.261719 443.328125 803.242188 C 441.675781 800.21875 441.316406 796.625 442.320312 793.316406 C 453.109375 755.5625 464.109375 718.027344 475.257812 680.847656 C 476.261719 678.117188 477.84375 675.597656 480.003906 673.65625 C 530.484375 622.746094 581.035156 572.121094 631.519531 521.78125 C 632.019531 521.277344 632.523438 520.917969 633.171875 520.703125 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      d="M 593.910156 375.875 L 593.910156 437.214844 L 156.691406 437.214844 L 156.691406 375.875 L 593.910156 375.875 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      d="M 593.835938 250.96875 L 593.835938 312.449219 L 156.621094 312.449219 L 156.621094 250.96875 L 593.835938 250.96875 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      d="M 564.570312 501.074219 C 535.660156 521.421875 507.902344 541.558594 479.285156 560.902344 C 476.046875 562.484375 472.453125 563.273438 468.855469 563.132812 L 164.960938 563.132812 C 162.375 563.132812 159.785156 563.132812 156.476562 562.484375 L 156.40625 501.074219 L 564.570312 501.074219 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <g clipPath="url(#e40a7c9882)">
      <path
        d="M 762.324219 582.976562 L 668.695312 490.574219 C 682.574219 475.832031 695.519531 460.441406 710.261719 447.140625 C 715.941406 442.679688 723.132812 440.453125 730.324219 440.8125 C 737.585938 441.171875 744.488281 444.191406 749.667969 449.226562 C 767.355469 465.835938 784.617188 483.023438 801.15625 500.785156 C 806.546875 506.753906 809.496094 514.449219 809.496094 522.5 C 809.496094 530.484375 806.546875 538.25 801.15625 544.148438 C 799.5 546.230469 797.632812 548.171875 795.761719 550.042969 L 762.324219 582.976562 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default BlogIcon
