export type WebLink = [name: string, url: string]

export const socials: WebLink[] = [
  ['Facebook', 'https://facebook.com/pevanandsarah'],
  ['Twitter', 'https://twitter.com/pevanandsarah'],
  ['Instagram', 'https://instagram.com/pevanandsarah'],
  ['YouTube', 'https://www.youtube.com/channel/UC8VyjeNlE1QbyQ7WWyUevHw'],
  ['Spotify', 'https://open.spotify.com/artist/18avlDuu2zI5Z4ESppOFvN'],
  ['Apple Music', 'https://music.apple.com/au/artist/pevan-sarah/933173417'],
  ['Email', 'mailto:admin@pevanandsarah.com']
]

export const footerLinks: WebLink[] = [
  ['Home', '/'],
  ['Cub Club', '/cub-club'],
  ['About', '/about'],
  // ['Blog', '/blog'],
  ['Events', '/events'],
  ['Contact', '/contact'],
  ['Privacy Policy', '/privacy'],
  ['Cookie Policy', '/cookies'],
  ['Terms of Service', '/terms']
]
