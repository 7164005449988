import { ArrowRightIcon } from '@heroicons/react/24/solid'
import PrintableImageButton from '~/modules/printables/components/PrintableImageButton'
import { type SearchItemRendererProps } from './types'

export const SearchItemPrintable = ({ item, onClick: _onClick, accentColor }: SearchItemRendererProps) => {
  return (
    <PrintableImageButton
      buttonComponent={({ onClick: onTriggerClick, printable: _printable }) => (
        <button
          onClick={() => {
            onTriggerClick()
          }}
          className="group flex w-full items-center justify-between rounded-md px-3 py-2 hover:bg-tigerGray">
          <div className="flex items-center space-x-2">
            {item.thumbnail && (
              <img
                src={item.thumbnail}
                alt={item.title ?? ''}
                className="block aspect-video w-16 rounded-sm bg-tigerGray"
                loading="lazy"
              />
            )}
            <p>{item.title}</p>
          </div>
          <ArrowRightIcon className={`ml-1 h-4 w-4 ${accentColor}`} />
        </button>
      )}
      printable={{
        id: item.id,
        image: {
          id: '',
          imageFile: {
            url: item.thumbnail
          }
        },
        isFreebie: false,
        title: item.title
      }}
    />
  )
}

export default SearchItemPrintable
