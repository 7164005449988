import useAuthMessage from './useAuthMessage'
import type { CurrentUser } from '~/modules/auth/types/session'

export interface AuthMessageProps {
  user?: CurrentUser | null
}
export const AuthMessage = ({ user }: AuthMessageProps) => {
  useAuthMessage({ user })
  return null
}

export default AuthMessage
