import { useEffect } from 'react'
import { useSearchParams } from '@remix-run/react'
import Cookies from 'js-cookie'

import type { CurrentUser } from '~/modules/auth/types/session'
import { useToast } from '~/components/Toast'

export enum AuthCode {
  WILL_TERMINATE = 'WILL_TERMINATE',
  WAS_TERMINATED = 'WAS_TERMINATED'
}

export interface UseAuthMessageProps {
  user?: CurrentUser | null
}

const useAuthMessage = ({ user }: UseAuthMessageProps) => {
  const [searchParams] = useSearchParams()
  const addToast = useToast()

  useEffect(() => {
    const authCode = searchParams.get('authCode')

    if (authCode) {
      switch (authCode) {
        case AuthCode.WILL_TERMINATE:
          // No point showing any toast unless the user actually has a session
          if (!user?.id) {
            return
          }

          const hasSeenWillTerminateMsg = Cookies.get('hasSeenWillTerminateMsg')
          if (!hasSeenWillTerminateMsg) {
            addToast({
              title: 'Login successful',
              description: 'Cub Club can be used on one device at a time. All other devices have now been logged out.',
              intent: 'secondary',
              duration: 30000
            })

            Cookies.set('hasSeenWillTerminateMsg', 'true')
          }
          break

        case AuthCode.WAS_TERMINATED:
          const hasSeenWasTerminatedMsg = Cookies.get('hasSeenWasTerminatedMsg')
          if (!hasSeenWasTerminatedMsg) {
            addToast({
              title: 'Multiple logins detected',
              description:
                "We've detected that you've logged into another device. Logging in elsewhere will log you out of here.",
              intent: 'warning',
              duration: 30000
            })
            Cookies.set('hasSeenWasTerminatedMsg', 'true')
          }
          break
      }

      // set a cookie to flag that this message has been seen. Cookie will expire when browser is closed
    }
  }, [searchParams, user?.id, addToast])
}

export default useAuthMessage
